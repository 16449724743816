.navbar-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #333;
  padding: 10px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px; /* Adjust based on your logo's size */
  width: auto; /* Adjust if you want a specific width */
  margin-right: 10px; /* Space between logo and brand name */
}

.brand-name {
  font-size: 24px;
  color: white;
  margin-right: 20px;
}

.nav-links {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.nav-link {
  margin: 0 15px;
  color: white;
  text-decoration: none;
}

.nav-link:hover, .brand-name:hover {
  text-decoration: underline;
}

.hamburger-menu {
  display: none; /* Hidden by default */
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.language-switcher {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.language-option {
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin: 1px 0; /* Closer together */
}

@media (max-width: 910px) { /* Adjusted breakpoint */
  .nav-links {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .menu-active .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your header's height */
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 20px 10px;
  }

  .nav-link {
    margin: 10px 0; /* More space for tap targets */
  }

  .menu-active .language-switcher {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 0;
    background-color: #333; /* Ensure consistency in background color */
  }
}
