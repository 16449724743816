.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-content {
  position: relative; /* Establish a positioning context */
  min-height: 100vh; /* Ensures it covers the full viewport height */
  padding-top: 70px; /* Increased padding to accommodate the header */
  padding-left: 10px;
  z-index: 1;
}

.main-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/background.webp'); /* Adjusted path */
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  opacity: 0.15; /* 25% visibility */
  z-index: -1; /* Ensure the background is behind the content */
}

.times-new-roman24 {
  font-family: Times New Roman, Helvetica, Arial, sans-serif;
  font-size: 24px;
}

.times-new-roman20 {
  font-family: Times New Roman, Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.times-new-roman20-indent {
  font-family: Times New Roman, Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-indent: 30px;
}

.constitutional-font {
  font-family: 'Fondamento', 'Noto Serif Display', serif;
  font-size: 28px;
  font-style: italic;
  text-align: center;
}

.full-width-image {
  width: 100%; /* Makes the image fit the width of its container */
  height: auto; /* Maintains the aspect ratio of the image */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.contact-main {
  padding-top: 30px; 
  padding-left: 10px; 
}
